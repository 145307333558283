import { useCallback } from "react";
import { IS_B2B, IS_CUSTOMER, LOGIN_PEOPLE_COMPANY } from "../config/constants";

let classTypeId =
  localStorage.getItem(IS_B2B) === "yes" &&
  JSON.parse(localStorage.getItem(LOGIN_PEOPLE_COMPANY)) !== null &&
  JSON.parse(localStorage.getItem(LOGIN_PEOPLE_COMPANY)).class_type &&
  JSON.parse(localStorage.getItem(LOGIN_PEOPLE_COMPANY)).class_type.id;

function useComponentHasPermission({ isInApp }) {
  const componentSourceId = isInApp ? "3" : "1";
  const getIdByClassType =
    localStorage.getItem(IS_B2B) === "yes" && classTypeId !== "7"
      ? classTypeId
      : localStorage.getItem(IS_B2B) === "yes" && classTypeId === "7"
      ? "1"
      : localStorage.getItem(IS_CUSTOMER) === "yes"
      ? "3"
      : "5";

  const asynGetPermission = useCallback(async () => {
    let permissionArr = [];
    let permissionArrId = [];

    const fetchPermissions = async (url) => {
      try {
        const res = await fetch(url);
        if (!res.ok) throw new Error(`Fetch failed: ${res.statusText}`);
        const data = await res.json();

        // Process permissions
        data.permissions.forEach((val) => {
          permissionArr.push(
            val.permission.name === "eWallets"
              ? "Flights"
              : val.permission.name,
          );
          permissionArrId.push(val.permission.id);
        });

        localStorage.setItem("permissionArr", JSON.stringify(permissionArr));
        localStorage.setItem(
          "permissionArrId",
          JSON.stringify(permissionArrId),
        );
      } catch (err) {
        console.error("Error fetching permissions:", err.message);
      }
    };

    const primaryUrl = `${process.env.REACT_APP_CDN_PRIMARY_URL}/component_has_permission/chp_${componentSourceId}_${getIdByClassType}.json`;
    const fallbackUrl = `${process.env.REACT_APP_CDN_FALLBACK_URL}/component_has_permission/chp_${componentSourceId}_${getIdByClassType}.json`;

    await fetchPermissions(primaryUrl);
    if (!permissionArr.length) {
      await fetchPermissions(fallbackUrl);
    }
  }, [componentSourceId, getIdByClassType]);

  return {
    asynGetPermission,
  };
}

export default useComponentHasPermission;
