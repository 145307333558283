import React, { useState, useEffect } from "react";
import { Grid, Button, Modal, Card, Popper } from "@mui/material";
import { clientV3 } from "../../utils/v3utils";
import {
  FIREBASE_LOGIN_BY_TOKEN_QUERY,
  RESET_PASSWORD,
  SEND_EMAIL_OTP,
} from "../../queries/loginquery";
import { CREATE_ARRANGER_B2C_PROFILE } from "../../queries/loginuserquery";
import firebase from "firebase/compat/app";
//import { initializeApp } from "firebase/app";
//import { CREATE_PEOPLE } from "queries/peoplequery";
import { validateEmail } from "../../utils/common";
import {
  //initializeAuth,
  signInWithPopup,
  //RecaptchaVerifier,
  GoogleAuthProvider,
  //FacebookAuthProvider,
  browserPopupRedirectResolver,
} from "firebase/auth";
import "firebase/compat/auth";
import {
  setToLocalStorage,
  //storeUserProfileInfo,
  storeUserProfileInformation,
} from "../../utils/loginutils";
import EmailSentImage from "../../assets/images/email_sent_icon.png";
import SigninEmailPassIcon from "../../assets/images/signin-email-pass.svg";
//import SigninPhonePassIcon from "../../assets/images/signin-phone-pass.svg";
import SigninOtpIcon from "../../assets/images/signin-otp.svg";
//import PhoneSignInIcon from "../../components/svg/PhoneSignInIcon";
import GoogleIcon from "../../components/svg/GoogleIcon";
import {
  LOGIN_PEOPLE_DETAIL,
  LOGIN_NAME,
  LOGIN_EMAIL,
  LOGIN_PHONE_PREFIX,
  LOGIN_PHONE,
  LOGIN_SOCIAL,
  LOGIN_TYPE,
  SOCIAL_TOKEN,
  LOGIN_USER_ID,
  AUTH_TOKEN,
  TOKEN_TYPE,
  EXPIRES_IN,
  IDENTIFIER,
  IS_CUSTOMER,
  LOGIN_VIA_KBZPAY,
} from "../../config/constants";
import { isAuthenticated, expireDate } from "../../lib/auth";
import { useTheme } from "@mui/material/styles";
import { isMobile } from "react-device-detect";
import { KBZ } from "../../config/inappclients";
import kbzpaylogo from "../../assets/images/payment_logo/kbzpay_logo.webp";
import useKBZPayConsent from "../../hooks/KBZPayConsentHook";
import { getCampaignId, getKBZPayToken, getutmId } from "../../utils/checkurl";
import { useTranslation } from "react-i18next";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import InfoIcon from "../../assets/images/info-icon.png";
import closeIcon from "../../assets/images/modal-close.svg";
import SignUpOptionsBox from "../login/SignUpOptionsBox";
import AgentTab from "./forms/AgentTab";
import CustomerEmailPassForm from "./forms/CustomerEmailPassForm";
import CustomerPhoneForm from "./forms/CustomerPhoneForm";
import CustomerEmailOtpForm from "./forms/CustomerEmailOtpForm";
import useFlymyaSessionHook from "../../hooks/FlymyaSessionHook";

const LoginSignupModal = ({
  isInApp,
  isInWeb,
  isShow,
  setIsShow,
  isCreateAcc,
  setIsSpinner,
  isDim,
  setIsDim,
  loginEmail,
  setLoginEmail,
  loginPassword,
  setLoginPassword,
  handleLogin,
  errorMsg,
  setErrorMsg,
  isForget,
  setIsForget,
  isSpinner,
  page,
  regValues,
  createdResPeople,
  setIsCheck,
  selectedLanguage,
  clientTag,
  handleContinue,
  data,
  addId,
  originalPage,
  setInfoOpen,
  setIsKbzSingLoading,
}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSignUp, setIsSignUp] = useState(
    page === "reset password" ? false : isCreateAcc ? true : false,
  );
  const [otp, setotp] = useState("");
  const [isPhoneOnly, setIsPhoneOnly] = useState(true);
  const [final, setfinal] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [clickSendOtp, setClickSendOtp] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [otpPassword, setOtpPassword] = useState("");
  const [otpErrMsg, setOtpErrMsg] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [chooseState, setChooseState] = useState(true);
  const [isEmailPass, setIsEmailPass] = useState(false);
  const [isPhone, setIsPhone] = useState(true);
  const [isPhonePass, setIsPhonePass] = useState(false);
  const [isEmailOtp, setIsEmailOtp] = useState(false);
  const [emailOtpErrMsg, setEmailOtpErrMsg] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [clickSendEmailOtp, setClickSendEmailOtp] = useState(false);
  const [kbzPayToken] = useState(getKBZPayToken(window.location.search));
  const kPayConsentSessionName = "loginViaKbzpay";
  const { saveWalletActivity } = useKBZPayConsent(kPayConsentSessionName);
  const [isShowSignup, setIsShowSignup] = useState(false);
  const [isSignupPhoneVerified, setIsSignupPhoneVerified] = useState(false);
  const [signupOTPUser, setSignupOTPUser] = useState(null);
  const [utmId] = useState(getutmId(window.location.search));
  const [isShowOTP, setIsShowOTP] = useState(false);
  const [signUpOTPLoading, setSignUpOTPLoading] = useState(false);

  firebase.initializeApp({
    apiKey: "AIzaSyARU2wL-jgf6OCs04WzmBHpk9nZesx1ZJQ",
    authDomain: "auth.flymya.com",
    projectId: "v3-flymya-com",
    storageBucket: "v3-flymya-com.appspot.com",
    messagingSenderId: "938069228539",
    appId: "1:938069228539:web:a2356ace20a8307c5d295e",
    measurementId: "G-TFVZTWJD9J",
  });
  const auth = firebase.auth();

  const [activeTab, setActiveTab] = useState(0);
  const [signUpValues, setSignUpValues] = useState({});
  const [isSocialLogin, setIsSocialLogin] = useState(false);
  const [socialLoginType, setSocialLoginType] = useState(null);
  const [socialAuthToken, setSocialAuthToken] = useState();
  const [phonenumber, setPhonenumber] = useState("");
  const [phoneCountryCode, setPhoneCountryCode] = useState("95");
  const [phoneCountryFlag, setPhoneCountryFlag] = useState("mm");
  const [campaignId] = useState(getCampaignId(window.location.search));

  const { t } = useTranslation();

  const { handleUpdateArrangerId } = useFlymyaSessionHook();

  const loginViaKbzpay =
    sessionStorage.getItem(LOGIN_VIA_KBZPAY) &&
    JSON.parse(sessionStorage.getItem(LOGIN_VIA_KBZPAY));

  const handleSocialLogin = (tokenAcc, pass, page) => {
    setIsSocialLogin(true);
    localStorage.setItem(LOGIN_SOCIAL, "true");

    const inputVal = pass
      ? {
          token: tokenAcc,
          password: otpPassword,
          device_type: "website",
          oneclick: false,
          client:
            clientTag && clientTag !== ""
              ? clientTag
              : isMobile
              ? "default"
              : "default",
        }
      : {
          token: tokenAcc,
          device_type: "website",
          oneclick: false,
          client:
            clientTag && clientTag !== ""
              ? clientTag
              : isMobile
              ? "default"
              : "default",
          utm_id: utmId && utmId !== "" ? Number(utmId) : "",
        };

    clientV3
      .mutate({
        mutation: FIREBASE_LOGIN_BY_TOKEN_QUERY,
        variables: {
          input: inputVal,
        },
      })
      .then((res) => {
        //Update Arranger ID Session
        handleUpdateArrangerId(
          res.data &&
            res.data.firebaseLoginByToken &&
            res.data.firebaseLoginByToken.user &&
            res.data.firebaseLoginByToken.user.arranger
            ? res.data.firebaseLoginByToken.user.arranger.id
            : null,
        );

        storeUserProfileInformation(res);
        page !== "signup" && setToLocalStorage(res);
        setSignUpOTPLoading(false);

        if (
          page !== "signup" &&
          res.data !== null &&
          res.data.firebaseLoginByToken !== null &&
          res.data.firebaseLoginByToken.user &&
          res.data.firebaseLoginByToken.user.arranger
        ) {
          setIsSpinner(true);
          setIsDim(true);
          if (isInApp) {
            window.location.href = `/${selectedLanguage}/in-app${window.location.search}`;
          } else if (isInWeb) {
            window.location.href = `/${selectedLanguage}/in-web${window.location.search}`;
          } else {
            if (originalPage && originalPage === "flightResult") {
              window.location.href = `/`;
            } else {
              window.location.href = `/${selectedLanguage}`;
            }
          }
        } else {
          localStorage.setItem(SOCIAL_TOKEN, tokenAcc);
          localStorage.setItem(
            LOGIN_USER_ID,
            res.data?.firebaseLoginByToken?.user?.id,
          );
          localStorage.setItem(
            AUTH_TOKEN,
            res.data?.firebaseLoginByToken?.access_token,
          );
          localStorage.setItem(
            TOKEN_TYPE,
            res.data?.firebaseLoginByToken?.token_type,
          );

          localStorage.setItem(
            EXPIRES_IN,
            expireDate(res.data?.firebaseLoginByToken?.expires_in),
          );
          localStorage.setItem(
            LOGIN_NAME,
            res.data &&
              res.data.firebaseLoginByToken &&
              res.data.firebaseLoginByToken.user
              ? res.data?.firebaseLoginByToken?.user?.name
              : "",
          );
          localStorage.setItem(
            LOGIN_EMAIL,
            res.data?.firebaseLoginByToken?.user?.email,
          );
          localStorage.setItem(LOGIN_PHONE_PREFIX, phoneCountryCode);
          localStorage.setItem(LOGIN_PHONE, phonenumber);

          setSignUpValues({
            adultInfo: [
              {
                id: res.data?.firebaseLoginByToken?.user?.id,
                firstName:
                  res.data &&
                  res.data.firebaseLoginByToken &&
                  res.data.firebaseLoginByToken.user
                    ? res.data?.firebaseLoginByToken?.user?.name
                    : "",
                email: res.data?.firebaseLoginByToken?.user?.email,
                mobilePrefix: phoneCountryCode,
                mobileNo: phonenumber,
              },
            ],
          });
          regValues
            ? (regValues.adultInfo[0] = signUpValues)
            : (regValues = { adultInfo: [signUpValues] });
          setIsSignUp(true);
          if (
            page === "signup" &&
            localStorage.getItem(LOGIN_EMAIL) !== "undefined"
          ) {
            setIsShow(true);

            setIsSignUp(true);
            setIsShowSignup(true);
            setIsShowOTP(false);
            setIsSignupPhoneVerified(false);
            setSocialLoginType("");
            //setToLocalStorage(res);
          }
        }
      })
      .catch((err) => {
        console.log(err);

        setErrorMsg("The user credentials were incorrect.");
        setIsSpinner(false);
        setIsDim(false);
      });
  };

  const signInWithGoogle = (page) => {
    signInWithPopup(
      auth,
      new GoogleAuthProvider(),
      browserPopupRedirectResolver,
    )
      .then((res) => {
        auth.currentUser
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            // Send token to your backend via HTTPS
            // ...
            setSocialAuthToken(idToken);
            setSocialLoginType("google");
            handleSocialLogin(idToken, null, page);
            localStorage.setItem(LOGIN_TYPE, "google");
          })
          .catch(function (error) {
            // Handle error
            console.log(error.message);
          });

        //setToken(res.credential.idToken);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // const signInWithFacebook = () => {
  //   signInWithPopup(new FacebookAuthProvider())
  //     .then((res) => {
  //       setSocialLoginType("facebook");
  //       handleSocialLogin(accessToken);
  //       setToken(res.credential.idToken);
  //     })
  //     .catch((error) => {
  //       console.log(error.message);
  //     });
  // };

  useEffect(() => {
    if (page === "addressBook") {
      setIsShow(true);
    }
    localStorage.setItem("loginPanel", "customer");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCreateAcc || page === "profile" || page === "addressBook") {
      setIsSignUp(true);
      setIsPhone(false);
    } else {
      setIsSignUp(false);
      setIsPhone(true);
    }
    setIsEmailOtp(false);
    setIsEmailPass(false);
    setIsPhoneOnly(false);
  }, [isShow, isCreateAcc, page]);

  const handleIsNumber = (number) => {
    let reg = /^[0-9]+$/;
    return reg.test(number);
  };

  const handleClearTimer = (interval) => {
    clearInterval(interval);
    setIsDisable(false);
  };

  const signInWithPhoneNumber = () => {
    const firstNo = phonenumber.charAt(0);
    if (
      phonenumber === "" ||
      phonenumber.length < 8 ||
      Number(firstNo) === 0 ||
      !handleIsNumber(phonenumber)
    ) {
      setOtpErrMsg("Please check your phone number.");
      return;
    }
    setClickSendOtp(true);
    let totalSec = 60;
    const interval = setInterval(() => {
      setIsDisable(true);
      totalSec = totalSec - 1;
      if (totalSec === 0) {
        handleClearTimer(interval);
      }
    }, 1000);

    const phoneNumberString = "+" + phoneCountryCode + phonenumber;

    let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");
    auth
      .signInWithPhoneNumber(phoneNumberString, verify)
      .then((result) => {
        setfinal(result);
        setClickSendOtp(false);
        setSendOtp(true);
        alert(
          "We have sent you a message with a code. Please use this code to sign in",
        );
        setIsPhoneOnly(true);
      })
      .catch((err) => {
        alert(err);
        setClickSendOtp(false);
        window.location.reload();
      });
  };

  const OTPValidation = (page) => {
    if (otp === null || final === null) return;
    final
      .confirm(otp)
      .then((result) => {
        auth.currentUser
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            // Send token to your backend via HTTPS
            // ...
            setSocialAuthToken(idToken);
            setSocialLoginType("phone");
            if (page === "signup") {
              isPhoneOnly
                ? handleSocialLogin(idToken, null, page)
                : handleSocialLogin(idToken, otpPassword, page);
            } else {
              isPhoneOnly
                ? handleSocialLogin(idToken)
                : handleSocialLogin(idToken, otpPassword);
            }
          })
          .catch(function (error) {
            // Handle error
            console.log(error.message);
            setSignUpOTPLoading(false);
          });
      })
      .catch((err) => {
        alert(err);
        setSignUpOTPLoading(false);
      });
  };

  const signInWithEmailOtp = () => {
    if (!validateEmail(emailOtp)) {
      setEmailOtpErrMsg("Please check your email address.");
      return;
    }
    setClickSendEmailOtp(true);

    clientV3
      .mutate({
        mutation: SEND_EMAIL_OTP,
        variables: {
          input: { email: emailOtp, client: clientTag ? clientTag : "default" },
        },
      })
      .then((res) => {
        alert(
          `We have sent a link to your email address ${emailOtp}. Please check your inbox to sign in with this email address.`,
        );
        //setIsShow(false);
        setClickSendEmailOtp(false);
      })
      .catch((err) => {
        console.log(err);
        setEmailOtpErrMsg("Something went wrong.");
        setClickSendEmailOtp(false);
      });
  };

  const createPeople = (values) => {
    setIsDim(true);
    let commonInput = {
      id: values.id,
      // login_id:
      //   localStorage.getItem("userId") && localStorage.getItem("userId") !== ""
      //     ? localStorage.getItem("userId")
      //     : null,
      prefix: values.signupPrefix,
      first_name: values.signupFirstName,
      last_name: values.signupLastName,
      phone_prefix: values.signupPhonePrefix,
      phone: values.signupPhone,
      email: values.signupEmail,
      nationality_id: values.signupNationality,
      dob:
        values.signupDobYear !== "0000" &&
        values.signupDobMonth !== "00" &&
        values.signupDobDay !== "00"
          ? `${values.signupDobYear}-${values.signupDobMonth}-${values.signupDobDay}`
          : null,
      passport: values.signupNrcPass,
      passport_exp:
        values.signupExpYear !== "0000" &&
        values.signupExpMonth !== "00" &&
        values.signupExpDay !== "00"
          ? `${values.signupExpYear}-${values.signupExpMonth}-${values.signupExpDay}`
          : null,
      nrc: values.signupNrc,
      address: values.address,
      township_id: values.township,
    };

    clientV3
      .mutate({
        mutation: CREATE_ARRANGER_B2C_PROFILE,
        variables: {
          input: commonInput,
        },
      })
      .then((res) => {
        if (res.data) {
          // setToLocalStorage(res);
          handleSocialLogin(socialAuthToken);
          // if (isInApp) {
          //   window.location.href = `/${selectedLanguage}/in-app?client=${clientTag}`;
          // } else {
          //   window.location.href = `/${selectedLanguage}`;
          // }
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMsg("Something went wrong.");
        setIsSpinner(false);
        setIsDim(false);
      });
  };

  const handleClearSendEmail = (interval) => {
    clearInterval(interval);
    setEmailSent(false);
    setIsForget(false);
    setIsEmailPass(false);
    setChooseState(true);
  };

  const handleResetPassword = async () => {
    setIsSpinner(true);
    try {
      const { data } = await clientV3.mutate({
        mutation: RESET_PASSWORD,
        variables: {
          input: {
            email: loginEmail,
          },
        },
      });

      if (data?.forgotPassword?.status === "EMAIL_NOT_SENT") {
        setIsSpinner(false);
        setErrorMsg("We can't find a user with that email address.");
      } else if (data?.forgotPassword?.status === "EMAIL_SENT") {
        setIsSpinner(false);
        setErrorMsg("");
        setEmailSent(true);
        let totalSec = 10;
        const interval = setInterval(() => {
          totalSec = totalSec - 1;
          if (totalSec === 0) {
            handleClearSendEmail(interval);
          }
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setIsSpinner(false);
    }
  };

  // If error occure in login page when user switch to sign up page, clear the error message.
  // Else the error message will be showing on sign up page without any reason.
  useEffect(() => {
    setErrorMsg("");
    const getLoginDetailFromLS = localStorage.getItem(LOGIN_PEOPLE_DETAIL);
    if (
      isAuthenticated() &&
      localStorage.getItem(IS_CUSTOMER) &&
      localStorage.getItem(IS_CUSTOMER) === "yes" &&
      localStorage.getItem(SOCIAL_TOKEN) &&
      localStorage.getItem(SOCIAL_TOKEN) !== "" &&
      (getLoginDetailFromLS === "false" ||
        getLoginDetailFromLS === "" ||
        getLoginDetailFromLS === "undefined")
    ) {
      setIsSignUp(true);
      setIsSocialLogin(
        localStorage.getItem(LOGIN_SOCIAL) &&
          localStorage.getItem(LOGIN_SOCIAL) === "true"
          ? true
          : false,
      );
      setSignUpValues({
        adultInfo: [
          {
            id: localStorage.getItem(LOGIN_USER_ID),
            firstName:
              localStorage.getItem(LOGIN_NAME) &&
              localStorage.getItem(LOGIN_NAME) !== "" &&
              localStorage.getItem(LOGIN_NAME) !== "null"
                ? localStorage.getItem(LOGIN_NAME)
                : "",
            email: localStorage.getItem(IDENTIFIER)
              ? localStorage.getItem(IDENTIFIER)
              : null,
            mobilePrefix: localStorage.getItem(LOGIN_PHONE_PREFIX),
            mobileNo: localStorage.getItem(LOGIN_PHONE),
          },
        ],
      });
      localStorage.getItem(SOCIAL_TOKEN) &&
        localStorage.getItem(SOCIAL_TOKEN) !== "" &&
        setSocialAuthToken(localStorage.getItem(SOCIAL_TOKEN));
    }
  }, [setErrorMsg]);

  useEffect(() => {
    if (page === "reset password") {
      setChooseState(false);
      setIsEmailPass(true);
      setErrorMsg("");
      setOtpErrMsg("");
    }
  }, [page, setErrorMsg]);

  return (
    <Modal
      open={isShow}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          setIsShow(true);
        }
        setIsForget(false);
        setIsPhoneOnly(false);
      }}
    >
      {/* Home Login Modal Box Css */}
      <Card
        className={`login-div ${
          isSignUp ? "signup-box-container" : "signin-box-container"
        }`}
      >
        <Grid
          item={true}
          sm={12}
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <img
            src={closeIcon}
            alt="close"
            style={{
              width: "25px",
              height: "25px",
              cursor: "pointer",
              position: "fixed",
              top: "11px",
            }}
            onClick={() => {
              setIsSignupPhoneVerified(false);
              setIsShow(false);
              setIsForget(false);
              setIsSignUp(false);
              setIsShowOTP(false);
              setSignupOTPUser(null);
            }}
            className="closeImg"
          />
        </Grid>
        <Grid container className="login-container">
          <Grid container className="login-inner">
            <Grid item={true} sm={12} md={12} xs={12} className="login-left">
              {!isSignUp &&
                (page === "home" || page === "reset password") &&
                !emailSent && (
                  <div className="login-left-inner signin">
                    <div className="login-title-div">
                      <h3>{isForget ? "Reset Password" : "Login"}</h3>
                    </div>
                    {!isInApp && !isInWeb && !isForget && (
                      <div className="div-login-tab">
                        <div
                          className={
                            activeTab === 0 ? "active login-tab" : "login-tab"
                          }
                          onClick={() => {
                            setIsCheck(false);
                            setActiveTab(0);
                            localStorage.setItem("loginPanel", "customer");
                          }}
                        >
                          {t("masterPassengerInformationTwo.customer_login") !==
                          "masterPassengerInformationTwo.customer_login"
                            ? t("masterPassengerInformationTwo.customer_login")
                            : "Customer Login"}
                        </div>

                        <div
                          className={
                            activeTab === 1 ? "active login-tab" : "login-tab"
                          }
                          onClick={() => {
                            setIsCheck(true);
                            setActiveTab(1);
                            localStorage.setItem("loginPanel", "agent");
                          }}
                        >
                          {t("masterPassengerInformationTwo.agent_login") !==
                          "masterPassengerInformationTwo.agent_login"
                            ? t("masterPassengerInformationTwo.agent_login")
                            : "Agent Login"}
                        </div>
                      </div>
                    )}

                    {activeTab === 1 && (
                      <AgentTab
                        isDim={isDim}
                        errorMsg={errorMsg}
                        setErrorMsg={setErrorMsg}
                        loginEmail={loginEmail}
                        setLoginEmail={setLoginEmail}
                        loginPassword={loginPassword}
                        setLoginPassword={setLoginPassword}
                        setIsCheck={setIsCheck}
                        handleLogin={handleLogin}
                        isSpinner={isSpinner}
                      />
                    )}

                    {chooseState && activeTab === 0 && (
                      <div>
                        {clientTag === KBZ &&
                          loginViaKbzpay &&
                          loginViaKbzpay.user &&
                          loginViaKbzpay.user.arranger && (
                            <>
                              <div className="infobox infobox-loginbuttons">
                                {((clientTag === KBZ &&
                                  loginViaKbzpay &&
                                  loginViaKbzpay.user &&
                                  loginViaKbzpay.user.arranger) ||
                                  (clientTag === KBZ &&
                                    isSignupPhoneVerified &&
                                    signupOTPUser)) && (
                                  <span
                                    className="login-benefit"
                                    style={{ padding: 0 }}
                                  >
                                    Hi{" "}
                                    {loginViaKbzpay?.user?.arranger?.first_name}{" "}
                                    {loginViaKbzpay?.user?.arranger?.last_name},
                                    <br />
                                    Welcome back. We recognize your phone number{" "}
                                    from your previous contact or booking with
                                    Flymya.
                                    <br />{" "}
                                    <span>
                                      Do you want us to automatically log you in
                                      with this phone number{" "}
                                      <span
                                        style={{
                                          color: `${theme.palette.primary.main}`,
                                        }}
                                      >
                                        {loginViaKbzpay?.user?.phone}
                                      </span>{" "}
                                      using KBZPay authentication?
                                    </span>{" "}
                                    <img
                                      src={InfoIcon}
                                      alt="info"
                                      width="20px"
                                      style={{
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                      }}
                                      onClick={(e) => {
                                        setIsOpen(true);
                                        setAnchorEl(e.currentTarget);
                                      }}
                                    />
                                  </span>
                                )}
                                {clientTag === KBZ ? (
                                  <Button
                                    style={{
                                      border: `1px solid ${theme.palette.primary.main}`,
                                    }}
                                    className="btn-login-box"
                                    onClick={() => {
                                      setIsKbzSingLoading &&
                                        setIsKbzSingLoading(true);

                                      saveWalletActivity({
                                        variables: {
                                          input: {
                                            gateway:
                                              window.location.search.includes(
                                                "miniapp",
                                              )
                                                ? "customer-info-kbzpay-miniapp"
                                                : "customer-info-kbzpay-apph5",
                                            token:
                                              window.location.search.includes(
                                                "miniapp",
                                              ) &&
                                              localStorage.getItem(
                                                "miniappinfo",
                                              ) !== "undefined"
                                                ? String(
                                                    JSON.parse(
                                                      localStorage.getItem(
                                                        "miniappinfo",
                                                      ),
                                                    )?.xm_string_callback_key,
                                                  )
                                                : kbzPayToken,
                                            trigger_from:
                                              "kbzpay_flight_homepage",
                                            client_id: 5,
                                          },
                                        },
                                      });
                                      setIsShow(false);
                                    }}
                                    startIcon={
                                      <img
                                        src={kbzpaylogo}
                                        alt=""
                                        style={{ width: 18, height: 18 }}
                                      />
                                    }
                                  >
                                    <span className="sign-text">
                                      {" "}
                                      {"sign in with kbzpay"}
                                    </span>
                                  </Button>
                                ) : null}
                              </div>
                              <div
                                style={{
                                  textAlign: "center",
                                  marginBottom: "20px",
                                }}
                              >
                                (OR)
                              </div>
                            </>
                          )}
                      </div>
                    )}

                    {isForget && activeTab === 0 && (
                      <p className="forget-info">
                        {t(`auth.email_associated`) !== "auth.email_associated"
                          ? t(`auth.email_associated`)
                          : "Enter the email associated with your account."}

                        <br />
                        {t(`auth.send_instructions_reset`) !==
                        "auth.send_instructions_reset"
                          ? t(`auth.send_instructions_reset`)
                          : " And we’ll send an email with instructions to Reset your password."}
                      </p>
                    )}
                    {!isEmailOtp &&
                      !isPhone &&
                      isEmailPass &&
                      activeTab === 0 && (
                        <CustomerEmailPassForm
                          isDim={isDim}
                          errorMsg={errorMsg}
                          setErrorMsg={setErrorMsg}
                          loginEmail={loginEmail}
                          setLoginEmail={setLoginEmail}
                          loginPassword={loginPassword}
                          setLoginPassword={setLoginPassword}
                          setIsCheck={setIsCheck}
                          handleLogin={handleLogin}
                          isSpinner={isSpinner}
                          isForget={isForget}
                          setIsForget={setIsForget}
                          handleResetPassword={handleResetPassword}
                          clientTag={clientTag}
                        />
                      )}

                    {isPhone && activeTab === 0 && (
                      <CustomerPhoneForm
                        setPhoneCountryCode={setPhoneCountryCode}
                        phoneCountryCode={phoneCountryCode}
                        setPhoneCountryFlag={setPhoneCountryFlag}
                        phoneCountryFlag={phoneCountryFlag}
                        otpErrMsg={otpErrMsg}
                        phonenumber={phonenumber}
                        setOtpErrMsg={setOtpErrMsg}
                        setPhonenumber={setPhonenumber}
                        isPhonePass={isPhonePass}
                        otpPassword={otpPassword}
                        setOtpPassword={setOtpPassword}
                        isDisable={isDisable}
                        signInWithPhoneNumber={signInWithPhoneNumber}
                        clickSendOtp={clickSendOtp}
                        sendOtp={sendOtp}
                        errorMsg={errorMsg}
                        otp={otp}
                        setotp={setotp}
                        OTPValidation={OTPValidation}
                        setSignUpOTPLoading={setSignUpOTPLoading}
                        signUpOTPLoading={signUpOTPLoading}
                      />
                    )}

                    {isEmailOtp && activeTab === 0 && (
                      <CustomerEmailOtpForm
                        otpErrMsg={otpErrMsg}
                        emailOtp={emailOtp}
                        setEmailOtpErrMsg={setEmailOtpErrMsg}
                        setEmailOtp={setEmailOtp}
                        validateEmail={validateEmail}
                        emailOtpErrMsg={emailOtpErrMsg}
                        clickSendEmailOtp={clickSendEmailOtp}
                        signInWithEmailOtp={signInWithEmailOtp}
                      />
                    )}

                    {/* Customer Login */}
                    {activeTab === 0 && (
                      <>
                        <div className="login-divider">
                          <span className="border-line">
                            <hr />
                          </span>
                          <span className="border-title">or login with</span>
                          <span className="border-line">
                            <hr />
                          </span>
                        </div>

                        {((clientTag === KBZ &&
                          !signupOTPUser &&
                          !isSignupPhoneVerified) ||
                          clientTag !== KBZ) && (
                          <div className="infobox infobox-loginbuttons">
                            {!isEmailPass && (
                              <Button
                                className="btn-login-box btn-email-password"
                                onClick={() => {
                                  setChooseState(false);
                                  setIsEmailPass(true);
                                  setErrorMsg("");
                                  setOtpErrMsg("");
                                  setIsPhone(false);
                                  setIsPhoneOnly(false);
                                  setIsEmailOtp(false);
                                  setSendOtp(false);
                                  setOtpPassword("");
                                  setEmailOtp("");
                                  setLoginEmail("");
                                  setLoginPassword("");
                                  setIsForget(false);
                                }}
                                startIcon={
                                  <img src={SigninEmailPassIcon} alt="" />
                                }
                              >
                                <span className="sign-text">
                                  Email + password
                                </span>
                              </Button>
                            )}
                            {!isInApp && (
                              <Button
                                className="btn-login-box"
                                onClick={signInWithGoogle}
                                startIcon={<GoogleIcon />}
                              >
                                <span className="sign-text">Google</span>
                              </Button>
                            )}
                            {!isPhone && (
                              <Button
                                className="btn-login-box"
                                onClick={() => {
                                  setChooseState(false);
                                  setIsPhone(true);
                                  setIsPhoneOnly(true);
                                  setErrorMsg("");
                                  setOtpErrMsg("");
                                  setIsEmailPass(false);
                                  setIsEmailOtp(false);
                                  setSendOtp(false);
                                  setPhonenumber("");
                                  setOtpPassword("");
                                  setEmailOtp("");
                                  setIsForget(false);
                                }}
                                startIcon={<img src={SigninOtpIcon} alt="" />}
                              >
                                <span className="sign-text">
                                  Phone number (SMS)
                                </span>
                              </Button>
                            )}
                            {!isEmailOtp && clientTag !== KBZ && (
                              <Button
                                className="btn-login-box"
                                onClick={() => {
                                  setChooseState(false);
                                  setIsEmailOtp(true);
                                  setErrorMsg("");
                                  setEmailOtpErrMsg("");
                                  setIsPhone(false);
                                  setIsPhoneOnly(false);
                                  setIsEmailPass(false);
                                  setSendOtp(false);
                                  setOtpPassword("");
                                  setEmailOtp("");
                                  setLoginEmail("");
                                  setIsForget(false);
                                }}
                                startIcon={
                                  <img src={SigninEmailPassIcon} alt="" />
                                }
                              >
                                <span className="sign-text">Email OTP</span>
                              </Button>
                            )}
                          </div>
                        )}
                        {!isSignUp &&
                          clientTag !== KBZ &&
                          !window.location.search.includes("miniapp") && (
                            <Button
                              className="create-account-btn"
                              onClick={() => {
                                setIsSignUp(true);
                                setIsPhone(false);
                                //remove the localStorage variable from signup otp
                                localStorage.removeItem(LOGIN_EMAIL);
                                localStorage.removeItem(LOGIN_PHONE);
                                localStorage.removeItem(LOGIN_NAME);
                              }}
                            >
                              Create account
                            </Button>
                          )}
                      </>
                    )}
                  </div>
                )}

              {/* After the email is sent, we will show this screen */}
              {emailSent && (
                <div className="login-left-inner signin email-sent">
                  <img src={EmailSentImage} alt="" />
                  <h2>
                    {" "}
                    {t("masterPassengerInformationTwo.check_your_email") !==
                    "masterPassengerInformationTwo.check_your_email"
                      ? t("masterPassengerInformationTwo.check_your_email")
                      : "Check your Email"}
                  </h2>
                  <p>
                    {t(`auth.sent_recover_instructions`) !==
                    "auth.sent_recover_instructions"
                      ? t(`auth.sent_recover_instructions`)
                      : " We have sent a password recovery Instructions to your email."}
                  </p>
                  <a
                    href="https://mail.google.com"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Button>
                      {" "}
                      {t(`auth.open_email`) !== "auth.open_email"
                        ? t(`auth.open_email`)
                        : "Open Email"}
                    </Button>
                  </a>
                  <span className="info">
                    {t("masterPassengerInformationTwo.didnot_receive_email") !==
                    "masterPassengerInformationTwo.didnot_receive_email"
                      ? t("masterPassengerInformationTwo.didnot_receive_email")
                      : "Did not receive the email?"}
                    {t(`auth.check_spam_filter`) !== "auth.check_spam_filter"
                      ? t(`auth.check_spam_filter`)
                      : "Check your spam filter."}
                  </span>
                </div>
              )}

              {(isSignUp ||
                (clientTag === KBZ &&
                  loginViaKbzpay &&
                  loginViaKbzpay.user &&
                  loginViaKbzpay.user.arranger) ||
                (clientTag === KBZ &&
                  signupOTPUser &&
                  isSignupPhoneVerified)) && (
                <SignUpOptionsBox
                  handleContinue={handleContinue}
                  addId={addId}
                  setIsShow={setIsShow}
                  setIsSignUp={setIsSignUp}
                  isSocialLogin={isSocialLogin}
                  socialLoginType={socialLoginType}
                  createPeople={createPeople}
                  setIsDim={setIsDim}
                  createdResPeople={createdResPeople}
                  page={page}
                  handleLogin={handleLogin}
                  errorMsg={errorMsg}
                  setErrorMsg={setErrorMsg}
                  isDim={isDim}
                  regValues={regValues}
                  signUpValues={signUpValues}
                  isSpinner={isSpinner}
                  setIsSpinner={setIsSpinner}
                  signInWithGoogle={signInWithGoogle}
                  setChooseState={setChooseState}
                  setIsPhone={setIsPhone}
                  setIsPhoneOnly={setIsPhoneOnly}
                  setOtpErrMsg={setOtpErrMsg}
                  setIsForget={setIsForget}
                  data={data}
                  setInfoOpen={setInfoOpen}
                  originalPage={originalPage}
                  signInWithPhoneNumber={signInWithPhoneNumber}
                  isSignUp={isSignUp}
                  isShowSignup={isShowSignup}
                  setIsShowSignup={setIsShowSignup}
                  setSignupOTPUser={setSignupOTPUser}
                  setIsSignupPhoneVerified={setIsSignupPhoneVerified}
                  isPhone={isPhone}
                  sendOtp={sendOtp}
                  setPhoneCountryCode={setPhoneCountryCode}
                  phoneCountryCode={phoneCountryCode}
                  phoneCountryFlag={phoneCountryFlag}
                  setPhoneCountryFlag={setPhoneCountryFlag}
                  otpErrMsg={otpErrMsg}
                  phonenumber={phonenumber}
                  setPhonenumber={setPhonenumber}
                  isPhonePass={isPhonePass}
                  otpPassword={otpPassword}
                  setOtpPassword={setOtpPassword}
                  isDisable={isDisable}
                  clickSendOtp={clickSendOtp}
                  setIsPhonePass={setIsPhonePass}
                  otp={otp}
                  setotp={setotp}
                  OTPValidation={OTPValidation}
                  signUpOTPLoading={signUpOTPLoading}
                  setSignUpOTPLoading={setSignUpOTPLoading}
                  setSendOtp={setSendOtp}
                  clientTag={clientTag}
                  signupOTPUser={signupOTPUser}
                  isInApp={isInApp}
                  campaignId={campaignId}
                  utmId={utmId}
                  setSocialLoginType={setSocialLoginType}
                  setIsEmailOtp={setIsEmailOtp}
                  setIsEmailPass={setIsEmailPass}
                  isShowOTP={isShowOTP}
                  setIsShowOTP={setIsShowOTP}
                />
              )}
            </Grid>

            {clientTag === "kbz" && isInApp && (
              <Grid
                item={true}
                sm={12}
                md={4}
                xs={12}
                className="login-benefit"
                style={{ borderTop: "1px solid #eee", paddingTop: 10 }}
              >
                <Popper
                  open={isOpen}
                  anchorEl={anchorEl || null}
                  placement="bottom-start"
                  style={{ zIndex: 10000 }}
                >
                  {({ placement }) => (
                    <ClickAwayListener
                      onClickAway={() => {
                        setIsOpen(false);
                      }}
                    >
                      <Card className="pasengerTypeCard">
                        <Grid container>
                          <Grid item={true} sm={12} xs={12}>
                            <p className="login-benefit">
                              Logging in allows you to get special benefits
                              through the Flymya Insider program and you can
                              book faster because you can re-use your personal
                              and traveler details from previous bookings.
                            </p>
                          </Grid>
                        </Grid>
                      </Card>
                    </ClickAwayListener>
                  )}
                </Popper>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
};

export default LoginSignupModal;
