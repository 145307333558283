import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//const BEUrl = process.env.REACT_APP_BE_SERVER;
const primaryUrl = `${process.env.REACT_APP_CDN_PRIMARY_URL}/lang.json`;
const fallbackUrl = `${process.env.REACT_APP_CDN_FALLBACK_URL}/lang.json`;

const getLangObj = async () => {
  try {
    let newObj = {};

    // Try fetching the primary URL first
    const res = await fetch(primaryUrl);
    const data = await res.json();
    Object.assign(newObj, data);

    return newObj;
  } catch (primaryError) {
    console.error("Primary fetch error:", primaryError.message);
    try {
      // If the primary URL fails, try the fallback URL
      const res = await fetch(fallbackUrl);
      const data = await res.json();
      return data; // Directly return the data from the fallback URL
    } catch (fallbackError) {
      console.error("Fallback fetch error:", fallbackError.message);
      return {}; // Return an empty object if both fetches fail
    }
  }
};

const lngKey = window.location.href.split("/")[3];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: getLangObj(),
    lng: lngKey && lngKey !== "" ? lngKey : "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
